import { useRef, useEffect, useState } from "react";
import { BASINS, COUNTIES } from "./redux/types";

import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color.js";

import { APIKey, AssetPath, IBaseMapConfig, BaseMapConfig } from "./config/BaseMapConfig";
import LayerIds from "./config/layers/LayerIds";

import { SET_BASINS, SET_COUNTIES, setSelectedType } from "./redux/locations";
import { INIT_MAP } from "./redux/esri";
import {
    fetchSubscriptionData,
} from "../store/actions/subscription-settings-actions";

import "../../App.css";
import "../map/css/map.scss";

import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getBasinCountyDetails } from "../store/actions/cart-select-basin-county-actions";
import { BasinDetailsObject, CountyDetailsObject } from "../models/redux-models";
import { showSiteLoader, hideSiteLoader } from "../store/actions/modal-actions";
import Zoom from "@arcgis/core/widgets/Zoom";
import { debounce, result } from "lodash";
import { queryLayers, utilityCartCheck } from "./utility/mapUtilities";


esriConfig.apiKey = APIKey;
esriConfig.assetsPath = AssetPath;
let webmap: WebMap;
let mapview: MapView;
let zoom: Zoom;
let countyLayerView: __esri.FeatureLayerView;
let basinLayerView: __esri.FeatureLayerView;
const debounceTime = 15;

interface IHighlight {
    attributes: any,
    remove: any
}

interface ISubscriptionMapData {
    type: number,
    name: string,
    state: string
}

interface IMapProps {
    allowSelection: boolean;
    disableNavigation?: boolean;
    zoomType?: "hidden" | "default" | "large";
    customExtent?: __esri.Extent;
    disablePopup?: boolean;
    defaultSelectedLayerType?: typeof BASINS | typeof COUNTIES;
}

function Map(props: IMapProps) {
    const { allowSelection } = props;
    const dispatch = useAppDispatch();
    const mapDiv = useRef(null);
    const [config, setConfig] = useState({});
    const [mapSet, setMapSet] = useState(false);
    const [basinLayerLoaded, setBasinLayerLoaded] = useState(false);
    const [countyLayerLoaded, setCountyLayerLoaded] = useState(false);
    const [layersLoaded, setLayersLoaded] = useState(false);
    const [screenPoint, setScreenPoint] = useState<any>({});
    const [currentEvent, setCurrentEvent] = useState({});
    const prevScreenPoint = useRef({});
    const [highlights, setHighlights] = useState<IHighlight[]>([])
    const [currentSubscriptions, setCurrentSubscriptions] = useState<ISubscriptionMapData[]>([]);
    const [subscriptionHighlights, setSubscriptionHighlights] = useState<IHighlight[]>([]);

    const {
        basins, counties, selectedType
    } = useAppSelector((state) => state.locations);

    const {
        auth: {
            user: { access_token },
        },
        cartSelectBasinCounty: {
            cartListItems,
            yearly,
            yearly_discount_percent
        },
        subscriptionSettings: { loadSubscriptionData, subscriptionData },
    } = useAppSelector((state) => state);

    useEffect(() => {
        if (mapDiv.current) {
            /**
             * Initialize config
             */
            setConfig(new BaseMapConfig());
            if (props.defaultSelectedLayerType === COUNTIES) {
                dispatch(setSelectedType(COUNTIES));
            } else {
                dispatch(setSelectedType(BASINS));
            }
        }
    }, []);

    useEffect(() => {
        if (access_token && loadSubscriptionData) {
            dispatch(fetchSubscriptionData(access_token));
        }
    }, [access_token, loadSubscriptionData])

    useEffect(() => {
        if (!subscriptionData) return;
        let tmpSubs: ISubscriptionMapData[] = [];
        const { details } = subscriptionData;
        details.forEach((item) => {
            tmpSubs.push({ type: item.line_item_type, name: item.line_item_name, state: item.state_code });
        });
        setCurrentSubscriptions(tmpSubs);
    }, [subscriptionData])

    useEffect(() => {
        if (basins.length === 0 && basinLayerView) {
            const newHighlights:IHighlight[] = [];
            highlights.forEach((h) => {
                if (!utilityCartCheck(h, cartListItems) && h.attributes.basin_name) {
                    h.remove.remove()
                } else {
                    newHighlights.push(h);
                }
            });
            setHighlights(newHighlights);
        }
    }, [basins]);
    
    useEffect(() => {
        if (counties.length === 0 && countyLayerView) {
            const newHighlights:IHighlight[] = [];
            highlights.forEach((h) => {
                if (!utilityCartCheck(h, cartListItems) && h.attributes.county_name) {
                    h.remove.remove()
                } else {
                    newHighlights.push(h);
                }
            });
            setHighlights(newHighlights);
        }
    }, [counties]);

    useEffect(() => {
        if (layersLoaded) {
            switch (selectedType) {
                case BASINS:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.BasinStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
                case COUNTIES:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.CountyStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
            }
        }
    }, [selectedType, layersLoaded]);

    useEffect(() => {
        if (basinLayerLoaded && countyLayerLoaded) {
            setLayersLoaded(true);
        }
    }, [basinLayerLoaded, countyLayerLoaded]);

    useEffect(() => {
        //@ts-ignore
        if (config !== null && config.hasOwnProperty("mapConfig") && !mapSet && !loadSubscriptionData) {
            setMapSet(true);
            //@ts-ignore
            const { mapConfig } = config;
            webmap = new WebMap({
                portalItem: {
                    id: mapConfig.webMapId
                }
            });

            mapview = new MapView({
                container: mapDiv.current == null ? undefined : mapDiv.current,
                map: webmap,
                extent: props.customExtent ?? undefined,
                ui: { components: ["attribution"] },
                constraints: {
                    snapToZoom: props.customExtent ? false : true
                },
            });

            
            if (props.zoomType !== "hidden") {
                zoom = new Zoom({ view: mapview, visible: false });
                mapview.ui.add(zoom, "top-right");
            }

            const updatePopupStyles = () => {
  
                const contentFeatureElement = document.querySelector(
                    ".esri-features__content-feature"
                );
    
                if (!contentFeatureElement || !contentFeatureElement.shadowRoot) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                

                const headerElement = contentFeatureElement.shadowRoot
                    .querySelector("calcite-panel")
                    ?.shadowRoot?.querySelector("div.header-container");

                if (!headerElement) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                const headerH2 = contentFeatureElement.querySelector("h2") as HTMLElement;

                if (!headerH2) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                headerH2.style.padding = "3px 10px";

                const headerActions = headerElement.querySelectorAll(".header-actions");

                headerActions.forEach((_action) => {
                    const action = _action as HTMLElement;
                    action.style.display = "none";
                });

                const contentContainer = contentFeatureElement.querySelector(".esri-features__container") as HTMLElement;
                contentContainer.style.display = "none";
    
                //The action bar (immediately beneath the title bar)
                const calciteActionBar =
                    contentFeatureElement.querySelector("calcite-action-bar") as HTMLElement;
    
                if (!calciteActionBar) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                calciteActionBar.style.display = "none";

                setTimeout(() => { mapview.popup.reposition() }, 200);
            };

            mapview.on("layerview-create", function (event: any) {
                if (event.layer.title === LayerIds.BasinStats) {
                    basinLayerView = event.layerView as __esri.FeatureLayerView;
                    basinLayerView.highlightOptions = {
                        color: new Color("#16A15E"),
                        haloOpacity: 0.9,
                        fillOpacity: 0.6
                    };
                    //@ts-ignore
                    //event.layer.popupTemplate = cartPopupTemplate;
                    setBasinLayerLoaded(true);
                    if (props.disablePopup) {
                        basinLayerView.layer.popupEnabled = false;
                        return;
                    }

                    const debouncedPointerMove = debounce((move_event: any) => {
                        if (mapview.layerViews.find((lv) => lv.layer.title === LayerIds.BasinStats).visible) {
                            mapview.hitTest(move_event, { include: basinLayerView.layer }).then((r) => {
                                //@ts-ignore
                                if (mapDiv.current) { mapDiv.current.style.cursor = "pointer"; }
                                showPopup(r, 'basin');
                                updatePopupStyles();
                                return;
                            });
                            //@ts-ignore
                            if (mapDiv.current) { mapDiv.current.style.cursor = "default"; }
                        }
                    }, debounceTime);
                    mapview.on('pointer-move', debouncedPointerMove);
                }
                if (event.layer.title === LayerIds.CountyStats) {
                    countyLayerView = event.layerView as __esri.FeatureLayerView;
                    countyLayerView.highlightOptions = {
                        color: new Color("#16A15E"),
                        haloOpacity: 0.9,
                        fillOpacity: 0.6
                    };
                    //@ts-ignore
                    //event.layer.popupTemplate = cartPopupTemplate;
                    setCountyLayerLoaded(true);
                    if (props.disablePopup) {
                        countyLayerView.layer.popupEnabled = false;
                        return;
                    }

                    const debouncedPointerMove = debounce((move_event: any) => {
                        if (mapview.layerViews.find((lv) => lv.layer.title === LayerIds.CountyStats).visible) {
                            mapview.hitTest(move_event, { include: countyLayerView.layer }).then((r) => {
                                //@ts-ignore
                                if (mapDiv.current) { mapDiv.current.style.cursor = "pointer"; }
                                showPopup(r, 'county');
                                updatePopupStyles();
                                return;
                            });
                            //@ts-ignore
                            if (mapDiv.current) { mapDiv.current.style.cursor = "default"; }
                        }
                    }, debounceTime);
                    mapview.on('pointer-move', debouncedPointerMove);
                }
            });

            if (props.disableNavigation) {
                function stopEvtPropagation(e: any) {
                    e.stopPropagation();
                }
                mapview.on("mouse-wheel", stopEvtPropagation);
                mapview.on("double-click", stopEvtPropagation);
                mapview.on("double-click", ["Control"], stopEvtPropagation);
                mapview.on("drag", stopEvtPropagation);
                mapview.on("drag", ["Shift"], stopEvtPropagation);
                mapview.on("drag", ["Shift", "Control"], stopEvtPropagation);
                mapview.on("key-down", (e) => {
                    const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
                    const keyPressed = e.key;
                    if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                        e.stopPropagation();
                    }
                });
            } else {
                mapview.on("click", (event: any) => {
                    setScreenPoint(event);
                });
            }

            webmap.when(() => {
                console.log("loaded");
                dispatch(INIT_MAP({ map: webmap, mapview }));
                webmap.layers
                    .filter(layer => { return layer.type === 'feature' })
                    .map(layer => {
                        let featLayer: FeatureLayer = layer as FeatureLayer;
                        featLayer.outFields = ['*'];
                        return featLayer;
                    });
                if (zoom) {
                    zoom.visible = true;
                }
            
                const updateZoomStyles = () => {
                    const zoomButtons = document.querySelectorAll(".esri-zoom .esri-widget--button");
                    if(zoomButtons.length === 0) {
                        setTimeout(() => updateZoomStyles(), 50);
                        return;
                    }
                    zoomButtons.forEach((el) => {
                        el.classList.add("esri-widget--button-large");
                        el.shadowRoot
                            ?.querySelector("calcite-icon")
                            ?.setAttribute("scale", "m");
                    });
                }
                if (props.zoomType === "large") {
                    updateZoomStyles();
                }
            });
        }
    }, [config, loadSubscriptionData]);

    useEffect(() => {
        if (screenPoint !== prevScreenPoint.current && mapSet)
            handleMapClick(screenPoint);
        prevScreenPoint.current = screenPoint;
    }, [screenPoint]);

    useEffect(() => {
        const tmpHighlights: IHighlight[] = [];
        let reset = false;
        //match highlights and remove items that have been removed from the cart
        highlights.forEach((item) => {
            if (!item.attributes.county_name) {
                const highlightIdx = cartListItems.findIndex((h) => {
                    const basinItem: BasinDetailsObject = h as BasinDetailsObject;
                    return basinItem.basin_name && basinItem.basin_name === item.attributes.basin_name;
                });
                if (highlightIdx === -1) {
                    item.remove.remove();
                    reset = true;
                } else {
                    tmpHighlights.push(item);
                }
            } else {
                const highlightIdx = cartListItems.findIndex((h) => {
                    const countyItem: CountyDetailsObject = h as CountyDetailsObject;
                    return countyItem.county_name && countyItem.county_name === item.attributes.county_name &&
                        countyItem.state_abbr && countyItem.state_abbr === item.attributes.state_abbr;
                });
                if (highlightIdx === -1) {
                    item.remove.remove();
                    reset = true;
                } else {
                    tmpHighlights.push(item);
                }
            }
        });
        const isNational = cartListItems.find((c: any) => c.line_item_type && c.line_item_type === 3);
        //query for and add items that have been added to the cart to highlight them
        if (isNational) {
            if (layersLoaded) {
                const blayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.BasinStats);
                const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats);
                if (layer && blayer) {
                    queryFeatures([blayer, layer], null, "1=1", tmpHighlights);
                }
            }
        } else {
            const addedBasinItems = cartListItems.filter((c: any) => {
                const highlightIdx = highlights.findIndex((h) => {
                    const basinItem: BasinDetailsObject = c as BasinDetailsObject;
                    return basinItem.basin_name && basinItem.basin_name === h.attributes.basin_name;
                });
                return highlightIdx === -1 && !c.county_name;
            })
            if (addedBasinItems.length > 0) {
                if (layersLoaded) {
                    const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.BasinStats);
                    if (layer) {
                        const clause = (addedBasinItems.map((ab: any) => ab.basin_name)).join("','");
                        queryFeatures([layer], null, "basin_name in ('" + clause + "')", tmpHighlights);
                        return;
                    }
                }
            }
            const addedCountyItems = cartListItems.filter((c: any) => {
                const highlightIdx = highlights.findIndex((h) => {
                    const CountyItem: CountyDetailsObject = c as CountyDetailsObject;
                    return h.attributes.county_name && CountyItem.county_name
                        && CountyItem.county_name === h.attributes.County_name
                        && CountyItem.state_abbr === h.attributes.State_abbr;
                });
                return highlightIdx === -1 && !c.basin_name;
            })
            if (addedCountyItems.length > 0) {
                if (layersLoaded) {
                    const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats);
                    if (layer !== null) {
                        const countyClause = (addedCountyItems.map((ab: any) =>
                            "(county_name = '" + ab.county_name + "' AND state_abbr = '" + ab.state_abbr + "')")).join(" OR ");
                        // const states = (addedCountyItems.map((ab: any) => ab.state_abbr)).join("','");
                        queryFeatures([layer], null, countyClause, tmpHighlights);
                        return;
                    }
                }
            }
        }
        //Update highlights
        if (reset) {
            console.log("reseting");
            setHighlights(tmpHighlights);
        }
    }, [cartListItems, layersLoaded]);

    useEffect(() => {
        if (layersLoaded && currentSubscriptions && !loadSubscriptionData) {
            subscriptionHighlights.forEach((h) => h.remove());
            const national = currentSubscriptions.filter((cs) => cs.type === 3);
            const cartPopupTemplate = {
                actions: [],
                content: "",
                title: (event: any) => {
                    const price = event.graphic.attributes.price;
                    const discountedPrice = price * (1 - yearly_discount_percent / 100);
                    let monthlyPriceNum = Math.trunc(price * 100) / 100;
                    let yearlyPriceNum = Math.trunc(discountedPrice * 12 * 100) / 100;
                    let monthlyPrice = monthlyPriceNum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    let yearlyPrice = yearlyPriceNum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    let formatted_price = monthlyPrice + " / month";
                    if (yearly) {
                        formatted_price = yearlyPrice + " / year";
                    }
                    
                    const subscribed = currentSubscriptions.find((cs) => (cs.name === event.graphic.attributes.basin_name) || (national && national.length > 0)) ?
                        "<br /><div className='subscribed'>Subscribed</div>" : "$" + formatted_price;
                    if (event.graphic.attributes.county_name) {
                        return "{county_name} - " + subscribed;
                    } else {
                        return "{basin_name} - " + subscribed;
                    }
                }
            }
            //@ts-ignore
            countyLayerView.layer.popupTemplate = cartPopupTemplate
            //@ts-ignore
            basinLayerView.layer.popupTemplate = cartPopupTemplate
            const query =
            {
                //query object
                where: "",
                returnGeometry: false,
                outFields: ["*"],
            }
            const basins = currentSubscriptions.filter((cs) => cs.type === 1);
            const bLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.BasinStats);
            const basinsClause = basins.length > 0 ? (basins.map((ab: ISubscriptionMapData) =>
                "basin_name = '" + ab.name + "'")).join(" OR ") : "1=0";
            query.where = (national && national.length > 0) ? "1=1" : basinsClause;
            dispatch(showSiteLoader());
            bLayer.queryFeatures(query)
                .then((featureSet: { features: any[]; }) => {
                    if (featureSet.features.length > 0) {
                        const tmpSubscriptionHighlights: any[] = [];
                        tmpSubscriptionHighlights.push(basinLayerView.highlight(featureSet.features));

                        const counties = currentSubscriptions.filter((cs) => cs.type === 2);
                        const countyClause = counties.length > 0
                            ? (counties.map((ab: ISubscriptionMapData) =>
                                "(county_name = '" + ab.name + "' AND state_abbr = '" + ab.state + "')")).join(" OR ")
                            : "1=0";
                        const cLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats);

                        query.where = (national && national.length > 0) ? "1=1" : "(" + countyClause + ") OR (" + basinsClause + ")";
                        queryLayers([cLayer], null, query).then((results) => {
                            results.forEach((featureSet: {features:any[]}) => {
                                if (featureSet.features.length > 0) {
                                    tmpSubscriptionHighlights.push(countyLayerView.highlight(featureSet.features));
                                }
                                setSubscriptionHighlights(tmpSubscriptionHighlights);
                            })
                            dispatch(hideSiteLoader());
                        })
                        .catch((e:any) => {
                            console.log("Error getting county subscription data");
                            setSubscriptionHighlights(tmpSubscriptionHighlights);
                            dispatch(hideSiteLoader());
                        });
                    }
                })
                .catch((e: any) => {
                    console.log("Error getting subscription data");
                })
                .finally(() => {
                    dispatch(hideSiteLoader());
                });
        }
    }, [currentSubscriptions, layersLoaded, yearly])

    if (config === null) {
        return <div className="mapDiv" ref={mapDiv}></div>;
    }

    const handleMapClick: any = (event: { preventDefault: () => void; }) => {
        if (allowSelection) {
            const compareType = selectedType === BASINS ? LayerIds.BasinStats : LayerIds.CountyStats;
            const layer = webmap.layers.find((l: { title: string; }) => l.title === compareType);
            queryFeatures([layer], event, "", [...highlights]);
        }
        event.preventDefault();
    }

    const showPopup: any = (r: any, popupType: string) => {
        if (r.results.length > 0) {

            const graphicHits = r.results?.filter(
                (hitResult: any) => hitResult.type === "graphic"
            );
            if (graphicHits.length === 0) {
                mapview.popup.visible = false;
                return;
            }
            const graphic = graphicHits[0].graphic;
            //remove to disable popups on subscribed items
            /*if (currentSubscriptions) {
                const found = currentSubscriptions.find((cs) => {
                    if (popupType === 'basin' && graphic.attributes.basin_name === cs.name)
                        return true;
                    if (popupType === 'county' && graphic.attributes.county_name === cs.name && graphic.attributes.state_abbr === cs.state)
                        return true;
                    return false;
                });
                if (found) return;
            }*/
            //console.log(graphic.attributes.OBJECTID);
            if (!mapview.popup.features || mapview.popup.features.length === 0
                || mapview.popup.features[0].attributes.OBJECTID !== graphic.attributes.OBJECTID) {
                mapview.openPopup({
                    location: graphic.geometry.centroid ? graphic.geometry.centroid : r.results[0].mapPoint,
                    features: [graphic]
                })
            } else {
                mapview.popup.visible = true;
            }
        } else {
            mapview.popup.visible = false;
        }
    }

    const queryFeatures = (layers: any[], screenPoint: any, clause: string = "", tmpHighlights: IHighlight[]) => {
        const point = screenPoint !== null ? mapview.toMap(screenPoint) : null;
        const query = (clause.length === 0) ?
        {
            //query object
            geometry: point,
            spatialRelationship: "intersects",
            returnGeometry: false,
            outFields: ["*"],
        } :
        {
            //query object
            where: clause,
            returnGeometry: false,
            outFields: ["*"],
        }
        dispatch(showSiteLoader());
        queryLayers(layers, point, query).then((results) => {
            results.forEach((featureSet: { features: any[]; }, i:number) => {
                // set graphic location to mouse pointer and add to mapview
                const features = featureSet.features.map((f: { attributes: any; }) => { return f.attributes });
                if (features.length === 0) {
                    return;
                }
                const featureAttributes = featureSet.features[0].attributes;
                if (layers[i].title === LayerIds.BasinStats) {
                    const basin = highlights.find((h) => h.attributes.basin_name && h.attributes.OBJECTID === featureAttributes.OBJECTID);
                    if (basin) return;

                    let tmpFeatures: any[] = [];

                    if (currentSubscriptions) {
                        features.forEach((f) => {
                            const found = currentSubscriptions.find((cs) => {
                                return (f.basin_name === cs.name || cs.type === 3);
                            });
                            if (!found) tmpFeatures.push(f);
                        });
                    }
                    if (screenPoint) {
                        dispatch(SET_BASINS(tmpFeatures));
                    }
                    const h = basinLayerView.highlight(featureSet.features.filter((f) => tmpFeatures.find((tmp) => tmp.OBJECTID === f.OBJECTID) !== null));
                    let tmpH = { attributes: featureAttributes, remove: h };
                    if (clause.length === 0) {
                        console.log('dispatching basin clicked')
                        dispatch(
                            getBasinCountyDetails(
                                access_token,
                                {
                                    category:
                                        "basin",
                                    search: `${features[0].basin_name}`,
                                },
                            )
                        );
                    }
                    tmpHighlights.push(tmpH);
                }
                if (layers[i].title === LayerIds.CountyStats) {
                    const county = highlights.find((h) => h.attributes.county_name && h.attributes.OBJECTID === featureAttributes.OBJECTID);
                    if (county) return;
                    let tmpFeatures: any[] = [];

                    if (currentSubscriptions) {
                        features.forEach((f) => {
                            const found = currentSubscriptions.find((cs) => {
                                return ((f.county_name === cs.name && f.state_abbr === cs.state) || cs.type === 3)
                            });
                            if (!found) tmpFeatures.push(f);
                        });
                    }
                    const h = countyLayerView.highlight(featureSet.features.filter((f) => tmpFeatures.find((tmp) => tmp.OBJECTID === f.OBJECTID) !== null));
                    if (screenPoint) {
                        dispatch(SET_COUNTIES(tmpFeatures));
                    }
                    let tmpH = { attributes: featureAttributes, remove: h };
                    if (clause.length === 0) {
                        console.log('dispatching county clicked')
                        dispatch(
                            getBasinCountyDetails(
                                access_token,
                                {
                                    category:
                                        "county",
                                    search: `${features[0].county_name}`,
                                    state: `${features[0].state_abbr}`,
                                },
                            )
                        );
                    }
                    tmpHighlights.push(tmpH);
                }
                setHighlights(tmpHighlights);
            })
        })
        .finally(() => {
            if (clause.length > 0) {
                return
            }
            dispatch(hideSiteLoader());
        });
    }

    const tmpConfig: IBaseMapConfig = config as IBaseMapConfig;
    return (<div className="mapDiv" ref={mapDiv}>
    </div>);
}

export default Map;
