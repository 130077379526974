import React, { useEffect, useLayoutEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { DownloadOptionIndex, ExportRowOption, tableColObje } from '../../models/redux-models'
import { handleDownloadCol, showHideCsvDownOpt, toggleChooseColExportToCsvModal } from '../../store/actions/wells-rigs-action'
import exportFromJSON from "export-from-json";
import moment from 'moment'
import { toast } from 'react-toastify'
import xlsx, { IContent } from 'json-as-xlsx'
import { stripTimezoneFromDate } from '../../../utils/helper'

function DownloadColSection() {
    const {
        wellsAndRigs: {
            tableCol,
            csvDownOpt,
            selectedWellRigOrPermitList,
            chooseColExportToCsvModal,
            wellsData: { data: wellsDataList, total_count: wellsTotalCount, page_size: wellPageSize },
            rigsData: { data: rigsDataList, total_count: rigsTotalCount, page_size: rigsPageSize },
            permitsData: { data: permitDataList, total_count: permitTotalCount, page_size: permitPageSize },
            productionData: { data: productionDataList, total_count: productionTotalCount, page_size: productionPageSize },
            tabIndex,
            rigsTableCol,
            productionCol,
            download_threshold
        },
    } = useAppSelector((state) => state);
    let data =
        tabIndex === 0
            ? (wellsDataList || []).map((item, index) => ({
                ...item,
            }))
            : tabIndex === 1
                ? (rigsDataList || []).map((item, index) => ({
                    ...item,
                }))
                : tabIndex === 2
                    ? (permitDataList || []).map((item, index) => ({
                        ...item,
                        // id: index + 1,
                    })) : (productionDataList || []).map((item, index) => ({
                        ...item,
                        // id: index + 1,
                    }));
    let count = tabIndex === 0 ? wellsTotalCount : tabIndex === 1 ? rigsTotalCount : tabIndex === 2 ? permitTotalCount : productionTotalCount;
    const header = tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol;

    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        downloadOptionIndx: DownloadOptionIndex,
        col: tableColObje[],
        rowsToExports: ExportRowOption,
        exportType: ExportRowOption
    }>({
        downloadOptionIndx: 1,
        col: [],
        rowsToExports: 1,
        exportType: 1
    })
    const downloadOption = [
        {
            label: "Displayed columns",
            indx: 1,
            onClick: (indx: DownloadOptionIndex) => {
                indx !== 1 && setState((prev) => ({ ...prev, downloadOptionIndx: 1 }));
                chooseColExportToCsvModal &&
                    dispatch(toggleChooseColExportToCsvModal());
            }
        },
        {
            label: "All columns",
            indx: 2,
            onClick: (indx: DownloadOptionIndex) => {
                indx !== 2 && setState((prev) => ({ ...prev, downloadOptionIndx: 2 }));
                chooseColExportToCsvModal &&
                    dispatch(toggleChooseColExportToCsvModal());
            }
        },
        {
            label: " Choose well data columns",
            indx: 3,
            onClick: (indx: DownloadOptionIndex) => {
                indx !== 3 && setState((prev) => ({ ...prev, downloadOptionIndx: 3 }));
                !chooseColExportToCsvModal &&
                    dispatch(toggleChooseColExportToCsvModal());
            }
        }
    ];

    const rowsToExportOption = [
        {
            label: "Selected rows",
            indx: 2,
            onClick: (indx: ExportRowOption) => {
                if (selectedWellRigOrPermitList.length === 0) {
                    toast.info("Please select at least one row for downloading.")
                    return
                };
                indx !== 2 && setState((prev) => ({ ...prev, rowsToExports: 2 }));
            }
        },
        {
            label: "All rows",
            indx: 1,
            onClick: (indx: ExportRowOption) => {
                indx !== 1 && setState((prev) => ({ ...prev, rowsToExports: 1 }));
            }
        },
    ];

    const exportType = [
        {
            label: "CSV",
            indx: 1,
            onClick: (indx: ExportRowOption) => {
                indx !== 1 && setState((prev) => ({ ...prev, exportType: 1 }));
            }
        },
        {
            label: "Excel",
            indx: 2,
            onClick: (indx: ExportRowOption) => {
                indx !== 2 && setState((prev) => ({ ...prev, exportType: 2 }));
            }
        },
    ];

    const getHeaders = () => {
        if (state['exportType'] == 1) {
            switch (state['downloadOptionIndx']) {
                case 1: return [...(header)
                    .filter((item) => item.status)
                    .map((_item) => (_item.label))];
                case 2: return [...(header)
                    .map((_item) => (_item.label))];
                case 3: return [...(state['col'])
                    .filter((item) => item.status)
                    .map((_item) => (_item.label))];
                default: return [];
            }
        }
        else {
            switch (state['downloadOptionIndx']) {
                case 1: return (tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol)
                    .filter((item) => item.status)
                    .map((_item) => ({
                        label: _item.header.toUpperCase(),
                        value: _item.label,
                    }));
                case 2: return (tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol)
                    .map((_item) => ({
                        label: _item.header.toUpperCase(),
                        value: _item.label,
                    }));
                case 3: return (state['col'])
                    .filter((item) => item.status)
                    .map((_item) => ({
                        label: _item.header.toUpperCase(),
                        value: _item.label,
                    }));
                default: return [];
            }

        }
    };

    useLayoutEffect(() => {
        if (selectedWellRigOrPermitList.length) {
            state['rowsToExports'] !== 2 && setState((prev) => ({ ...prev, rowsToExports: 2 }));
        } else {
            state['rowsToExports'] !== 1 && setState((prev) => ({ ...prev, rowsToExports: 1 }));
        }

    }, [selectedWellRigOrPermitList])

    useEffect(() => {
        if (state['downloadOptionIndx']) {
            const col = tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol
            JSON.stringify(col) !== JSON.stringify(state['col']) && setState((prev) => ({
                ...prev,
                col
            }))
        }
    }, [state['downloadOptionIndx']]);

    return (
        <div className={`downloadCon ${csvDownOpt ? "" : "d-none"}`}>
            <div className="header">
                Export Settings
            </div>
            <div className="export">
                <Scrollbars
                    className={"expportScroll"}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={250}
                    // autoHeightMax="25vh"
                    renderThumbVertical={(props) => (
                        <div {...props} className="thumb-vertical" />
                    )}
                    renderTrackVertical={(props) => (
                        <div {...props} className="track-vertical" />
                    )}

                >
                    <h3>Columns to export</h3>
                    <ul>
                        {
                            downloadOption.map((item, index) => {
                                return <li onClick={(e) => {
                                    item.onClick(state['downloadOptionIndx'])
                                }} key={index} className={`${state['downloadOptionIndx'] === item['indx'] ? "active" : ""} cursor`}>
                                    <span></span>
                                    {item['label']}
                                </li>
                            })
                        }
                    </ul>
                    <div className={chooseColExportToCsvModal ? '' : "d-none"}>
                        <h3>Selected well data columns:</h3>
                        <ul>
                            {state["col"].map((item, index) => {
                                return item.status ?
                                    <li key={index} className="active cursor" onClick={() => {
                                        setState((prev) => ({
                                            ...prev,
                                            col: prev.col.map((_item) => _item.header === item.header ? { ..._item, status: false } : _item)
                                        }))
                                    }}>
                                        <span></span>
                                        {item.header}
                                    </li>
                                    : <React.Fragment key={index}></React.Fragment>
                            })}
                        </ul>
                        <div className="line">&nbsp;</div>
                        <h3>Well data columns not being exported:</h3>
                        <ul>
                            {state['col'].map((item, index) => {
                                return !item.status ? <li key={index} className={"cursor"} onClick={() => {
                                    setState((prev) => ({
                                        ...prev,
                                        col: prev.col.map((_item) => _item.header === item.header ? { ..._item, status: true } : _item)
                                    }))
                                }}>
                                    {item.header}
                                </li> : <React.Fragment key={index}></React.Fragment>
                            })}
                        </ul>
                    </div>
                    <h3>Rows to export</h3>
                    <ul>
                        {rowsToExportOption.map((item, index) => {
                            return <li onClick={() => {
                                item.onClick(state['rowsToExports']);
                            }} className={`${state['rowsToExports'] === item['indx'] ? "active" : ""} cursor`} key={"rows" + index}>
                                <span></span>
                                {item['label']}
                            </li>
                        })}
                    </ul>
                    <h3>File type to export</h3>
                    <ul>
                        {exportType.map((item, index) => {
                            return <li onClick={() => {
                                item.onClick(state['exportType']);
                            }} className={`${state['exportType'] === item['indx'] ? "active" : ""} cursor`} key={"type" + index}>
                                <span></span>
                                {item['label']}
                            </li>
                        })}
                    </ul>
                </Scrollbars>
            </div>
            <div className="buttons">
                <button type="button" className="btn btn-primary" onClick={() => {
                    if ((Number(count) > download_threshold && state['rowsToExports'] === 1) || (state['rowsToExports'] === 2 && selectedWellRigOrPermitList.length > download_threshold)) {
                        dispatch(handleDownloadCol({ downloadCol: 1, allCol: state['downloadOptionIndx'] === 2 ? 1 : 0, ...(state['exportType'] === 2 && { excel_format: true }) }))
                        return
                    }

                    dispatch(handleDownloadCol({ downloadCol: 1, allCol: state['downloadOptionIndx'] === 2 ? 1 : 0, ...(state['exportType'] === 2 && { excel_format: true }), download_without_api_call: true }));

                    let temp_data = (state['rowsToExports'] as ExportRowOption) === 2 ? selectedWellRigOrPermitList : data;
                    if (tabIndex === 0) {
                        temp_data = temp_data.map((item: any) => {
                            const newItem = { ...item }
                            const dateStrings = ["added_on", "completion_date", "permit_date", "production_date", "spud_date", "updated_on", "rig_on_pad_date", "permit_exp_date"]
                            dateStrings.forEach((dateString: string) => {
                                if (dateString in newItem) {
                                    newItem[dateString] = stripTimezoneFromDate(newItem[dateString])
                                }
                            })
                            return newItem
                        })
                    }
                    let fileName = `ED_Data_Export_${moment(new Date()).format(
                        "MMM-DD-YYYY, h:mm:ss a"
                    )}`;
                    if (state['exportType'] === 1) {
                        exportFromJSON({
                            data: temp_data, fileName, exportType: "csv",
                            fields: [
                                ...(getHeaders() as string[])
                            ],
                            beforeTableEncode: entries => entries.map(
                                ({ fieldName, fieldValues }) => {
                                    return { fieldName: (header.find((item) => item['label'] === fieldName) as tableColObje)['header'], fieldValues };
                                }
                            )
                        })
                    } else {
                        let excel_data = [

                            {
                                sheet: `${tabIndex === 0 ? "Wells" : "Rigs"}`,
                                columns: [
                                    ...(getHeaders() as {
                                        label: string;
                                        value: string;
                                    }[])
                                ],
                                content: (temp_data as IContent[]),
                            },
                        ]
                        xlsx(excel_data, {
                            fileName,
                            extraLength: 5,
                        })
                    }

                }}>Export</button>
                <button type="button" className="btn btn-outline"
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(handleDownloadCol({ downloadCol: 0, allCol: 0, excel_format: false }))
                        chooseColExportToCsvModal &&
                            dispatch(toggleChooseColExportToCsvModal());
                        dispatch(showHideCsvDownOpt());
                    }}
                >Cancel</button>
            </div>
        </div>
    )
}

export default DownloadColSection