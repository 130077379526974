import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    ProductionDataObj,
    RigsDataObj,
    tableColObje,
    WellLineObject,
    WellsAndPermitsObject,
    WellsRigsModel,
} from "../../models/redux-models";

export const wellColumnInitialData: tableColObje[] = [
    {
        header: "Well Name",
        label: "well_name",
        status: true,
    },
    {
        header: "Well Api",
        label: "well_api",
        status: true,
    },
    {
        header: "Well Status",
        label: "well_status",
        status: true,
    },
    {
        header: "Current Operator",
        label: "operator_name",
        status: true,
    },
    {
        header: "County",
        label: "county",
        status: true,
    },
    {
        header: "State",
        label: "state_abbr",
        status: true,
    },
    {
        header: "Basin",
        label: "basin_name",
        status: true,
    },
    {
        header: "Measured Depth",
        label: "measured_depth",
        status: true,
    },
    {
        header: "True Vertical Depth",
        label: "true_vertical_depth",
        status: true,
    },
    {
        header: "Lateral Length",
        label: "lateral_length",
        status: true,
    },
    {
        header: "Permit Date",
        label: "permit_date",
        status: true,
    },
    {
        header: "Spud Date",
        label: "spud_date",
        status: true,
    },
    {
        header: "Completion Date",
        label: "completion_date",
        status: true,
    },
    {
        header: "Well Type",
        label: "well_type_name",
        status: true,
        dbKeyName: "well_type",
    },

    {
        header: "RESERVOIR",
        label: "reservoir",
        status: true,
    },
    {
        header: "Spud Source",
        label: "spud_source",
        status: true,
    },
    {
        header: "Rig on Pad Date",
        label: "rig_on_pad_date",
        status: true,
    },
    {
        header: "Rig on Pad Source",
        label: "rig_on_pad_source",
        status: true,
    },
    {
        header: "Abstract",
        label: "abstract",
        status: false,
    },
    {
        header: "Added On",
        label: "added_on",
        status: false,
    },
    {
        header: "Alternate Link",
        label: "alternate_link",
        status: false,
    },
    {
        header: "Block",
        label: "block",
        status: false,
    },
    {
        header: "Elevation",
        label: "elevation",
        status: false,
    },
    {
        header: "Field",
        label: "field",
        status: false,
    },
    {
        header: "First Production Date",
        label: "production_date",
        status: false,
    },
    {
        header: "Lateral Length Source",
        label: "lateral_length_source",
        status: false,
    },
    {
        header: "Latitude",
        label: "latitude",
        status: false,
    },
    {
        header: "Longitude",
        label: "longitude",
        status: false,
    },
    {
        header: "Line Geometry",
        label: "line_geometry",
        status: false,
    },
    {
        header: "Lot",
        label: "legaldesc_lot",
        status: false,
    },
    {
        header: "Original Operator",
        label: "original_operator",
        status: false,
    },
    {
        header: "Permit Count",
        label: "permit_count",
        status: false,
    },
    {
        header: "Permit Exp Date",
        label: "permit_exp_date",
        status: false,
    },
    {
        header: "Point Geometry",
        label: "point_geometry",
        status: false,
    },
    {
        header: "Production Type",
        label: "production_type",
        dbKeyName: "well_product",
        status: false,
    },
    {
        header: "Quarter Section",
        label: "quartersection",
        status: false,
    },
    {
        header: "Range",
        label: "range",
        status: false,
    },
    {
        header: "Section",
        label: "section",
        status: false,
    },
    {
        header: "Stacked Lateral",
        label: "stacked_lateral",
        status: false,
    },
    {
        header: "State Link",
        label: "state_link",
        status: false,
    },
    {
        header: "State Name",
        label: "state_name",
        status: false,
    },
    {
        header: "Survey",
        label: "survey",
        status: false,
    },
    {
        header: "Township",
        label: "township",
        status: false,
    },
    {
        header: "Updated On",
        label: "updated_on",
        status: false,
    },
    {
        header: "Well Orientation",
        label: "drill_type",
        status: false,
    },

    {
        header: "First 3mo Liq",
        label: "first_3mo_oil",
        status: false,
    },
    {
        header: "First 6mo Liq",
        label: "first_6mo_oil",
        status: false,
    },
    {
        header: "First 12mo Liq",
        label: "first_12mo_oil",
        status: false,
    },
    {
        header: "First 3mo Gas",
        label: "first_3mo_gas",
        status: false,
    },
    {
        header: "First 6mo Gas",
        label: "first_6mo_gas",
        status: false,
    },
    {
        header: "First 12mo Gas",
        label: "first_12mo_gas",
        status: false,
    },
    {
        header: "First 3mo BOE",
        label: "first_3mo_boe",
        status: false,
    },
    {
        header: "First 6mo BOE",
        label: "first_6mo_boe",
        status: false,
    },
    {
        header: "First 12mo BOE",
        label: "first_12mo_boe",
        status: false,
    },
    {
        header: "Producing Months",
        label: "producing_months",
        status: false,
    },
    {
        header: "Peak Liq Vol",
        label: "peak_oil_volume",
        status: false,
    },
    {
        header: "Peak Liq Mon",
        label: "peak_oil_volume_month",
        status: false,
    },
    {
        header: "Peak Gas Vol",
        label: "peak_gas_volume",
        status: false,
    },
    {
        header: "Peak Gas Mon",
        label: "peak_gas_volume_month",
        status: false,
    },
].map((item: tableColObje) => {
    return { ...item, tableColName: "default", tabIndex: 0 };
});

export const rigColumnInitialState: tableColObje[] = [
    {
        header: "Driller",
        label: "driller",
        status: true,
    },
    {
        header: "Well Name",
        label: "well_name",
        status: true,
        dbKeyName: "rig_well_name",
    },
    {
        header: "Well Api",
        label: "well_api",
        status: true,
        dbKeyName: "rig_api_id",
    },
    {
        header: "Operator",
        label: "operator_name",
        status: true,
        dbKeyName: "rig_operator_name",
    },
    {
        header: "County",
        label: "county",
        status: true,
        dbKeyName: "rig_county",
    },
    {
        header: "State",
        label: "state_abbr",
        status: true,
        dbKeyName: "rig_state_abbr",
    },
    {
        header: "Basin",
        label: "basin_name",
        status: true,
    },
    {
        header: "Orientation",
        label: "drill_type",
        status: true,
    },
    {
        header: "RESERVOIR",
        label: "reservoir",
        status: true,
    },

    {
        header: "Township",
        label: "legaldesc_township",
        status: false,
    },

    {
        header: "Range",
        label: "range",
        status: false,
    },

    {
        header: "Section",
        label: "section",
        status: false,
    },

    {
        header: "Quarter Section",
        label: "quartersection",
        status: false,
    },

    {
        header: "Block",
        label: "block",
        status: false,
    },
    {
        header: "Survey",
        label: "survey",
        status: false,
    },
].map((item: tableColObje) => {
    return { ...item, tableColName: "default", tabIndex: 1 };
});
const initialState: WellsRigsModel = {
    comp: true,
    advFilter: false,
    colProperties: false,
    fullScreen: false,
    csvDownOpt: false,
    chooseColExportToCsvModal: false,
    exportOtherCsvModal: false,
    /* NOTE:  I'm introducing a second state variable for tableCol and tableColFullList.  This tableCol refers to 
    the selected & ordered columns in the main wells table on the home screen. We allow for saving different configurations
    of this table, so all of these different options will be in the tableColFullList.  Then, based on the
    tableColName, the tableColFullList is filtered once in redux, to avoid repetitive filtering in the 
    react components.  
    This same approach applies to rigsTableCol and rigsTableColFullList */
    tableCol: wellColumnInitialData,
    tableColFullList: wellColumnInitialData,
    tabIndex: 0,
    wellsData: {
        data: null,
        total_count: 0,
        page_size: 0,
        /* NOTE:- After the code refactoring made by @Jacob while implementing the fuzzy logic, an issue occurred where the default value was not passed in the payload during initial loading. I attempted to use useLayoutEffect and useEffect to update the formState key in the Redux store, but it did not work due to delays in the update.
        To resolve this, I changed the logic as follows:
         Set the default loading flag of the wells data to false.
         Manually called the onSubmit function after setting the default value to the form, which then makes the loading flag true again.
         The reason for this approach is that the onSubmit function will fetch the latest value from the form, thereby overcoming the issue with default value updates.*/
        wellsDataLoading: false,
    },
    mapData: [{ data: null }],
    mapLineData: [{ data: null }],
    rigsData: {
        data: null,
        total_count: 0,
        page_size: 0,
        rigsDataLoading: true,
    },
    permitsData: {
        data: null,
        total_count: 0,
        page_size: 0,
        permitsDataLoading: true,
    },
    productionData: {
        data: null,
        total_count: 0,
        page_size: 0,
        productionDataLoading: true,
    },
    productionDataLoading: true,
    selectedAoiData: {
        aoi_id: 0,
    },
    rigsTableCol: rigColumnInitialState,
    rigsTableColFullList: rigColumnInitialState,
    wellsPage: 1,
    rigsPage: 1,
    permitsPage: 1,
    productionPage: 1,
    filterSearch: "",
    sort_by: "",
    sort_order: null,
    viewAnalytics: false,
    groupChoices: [
        { value: "1", label: "Wells" },
        { value: "2", label: "Permits" },
        { value: "3", label: "Rigs" },
        { value: "4", label: "All (AND)" },
        { value: "5", label: "At least one (OR)" },
        { value: "6", label: "Production" },
    ],
    wellsAndPermitFieldChoices: [
        { value: "1", label: "Operator" },
        { value: "2", label: "Well name" },
        { value: "3", label: "API" },
        { value: "4", label: "Well type" },
        { value: "7", label: "Depth" },
        { value: "8", label: "Well Orientation" },
        { value: "9", label: "County" },
        { value: "10", label: "State" },
        { value: "11", label: "Basin" },
        { value: "13", label: "Section" },
        { value: "14", label: "Township" },
        { value: "15", label: "Range" },
        { value: "16", label: "Spud Date" },
        { value: "17", label: "Completion Date" },
        { value: "18", label: "Production Date" },
        // { value: "19", label: "Gas" },
        // { value: "20", label: "Oil" },
        { value: "22", label: "Cum Oil" },
        { value: "23", label: "Cum Gas" },
        // { value: "24", label: "Reservoir" },
        // { value: "21", label: "Other" },
    ],
    rigsFieldChoices: [
        { value: "1", label: "Operator" },
        { value: "2", label: "Well name" },
        { value: "3", label: "Api" },
        { value: "4", label: "Driller" },
        { value: "5", label: "Orientation" },
        { value: "6", label: "Reservoir" },
        { value: "7", label: "Quarter Section" },
        { value: "8", label: "Survey" },
        { value: "9", label: "County" },
        { value: "10", label: "State" },
        { value: "11", label: "Basin" },
        { value: "12", label: "Block" },
        { value: "13", label: "Section" },
        { value: "14", label: "Township" },
        { value: "15", label: "Range" },
        { value: "16", label: "Spud Date" },
        { value: "17", label: "Permit Date" },
        // { value: "6", label: "Interest type" },
        // { value: "7", label: "Depth" },
        // { value: "12", label: "Lease" },
        // { value: "17", label: "Completion Date" },
        // { value: "5", label: "Profile" },
        // { value: "18", label: "Production Date" },
        // { value: "19", label: "Gas" },
        // { value: "20", label: "Oil" },
        // { value: "21", label: "Other" },
    ],
    operatorChoices: [
        { value: "1", label: "is equal to" },
        { value: "2", label: "is not equal to" },
        { value: "3", label: "is greater than" },
        { value: "4", label: "is between" },
        { value: "5", label: "is less than" },
        { value: "6", label: "exist" },
        { value: "7", label: "does not exist" },
        { value: "8", label: "contain" },
        { value: "9", label: "does not contain" },
        { value: "10", label: "starts with" },
        { value: "11", label: "where at least one" },
        { value: "12", label: "before" },
        { value: "13", label: "after" },
        { value: "14", label: "between" },
    ],
    filter: "",
    filter_param: [],
    optionChoice: [
        { value: "1", label: "All (AND)" },
        { value: "2", label: "At least one (OR)" },
    ],
    segment_id: 0,
    showTableLoader: false,
    selectedRowId: "",
    downloadCol: 0,
    downloadColMsg: "",
    allCol: 0,
    showTable: true,
    showSegmentDropDown: false,
    productionCol: [
        {
            header: "Well Name",
            label: "well_name",
            status: true,
        },
        {
            header: "Api",
            label: "well_api",
            status: true,
        },
        {
            header: "Operator",
            label: "operator_name",
            status: true,
        },
        {
            header: "County",
            label: "county",
            status: true,
        },
        {
            header: "State",
            label: "state_abbr",
            status: true,
        },
        {
            header: "Cum Oil",
            label: "cum_oil",
            status: true,
        },
        {
            header: "Cum Gas",
            label: "cum_gas",
            status: true,
        },
        {
            header: "Status",
            label: "well_status",
            status: true,
        },
    ],
    analyticsData: {
        prodLiq: [],
        prodGas: [],
        gasAggDate: [],
        gasAggMonth: [],
        liqAggDate: [],
        liqAggMonth: [],
        singleWellProd: [],
        singleWellLeaseProd: [],
        leaseIDs: [],
        hasWellProd: false,
        hasLeaseProd: false,
        allocated: false,
        graphDataLoading: true,
        type: "monthlyTab",
        xAxisFilter: 1,
        normalized: false,
        action: "none",
        apiList: [],
        apiListObj: [],
        apiListObjLength: 0,
        forecastingData: {
            dataLoading: true,
            dataList: null,
            forecastingCompleteDataFrame: "",
            eur: "",
            qi: "",
            b: "",
            ai: "",
            tlim: "",
            start_date_select: "",
            peakmo: 0,
        },
        selectedForecastPoints: null,
    },
    fullScrnAnalytics: false,
    fullScrnAnalyticsType: "oil",
    selectedWellRigOrPermitList: [],
    selectedWellRigOrPermitUIDList: [],
    donutChart: {
        dataLoading: true,
        dataList: [],
    },
    operatorPieChart: {
        operatorPieChartDataLoading: true,
        operatorPieChartDataList: {},
    },
    analyticsTabIndex: 0,
    productionTabIndex: 0,
    openForeCast: false,
    resizableWidth: 70,
    resizableHeight: "17rem",
    loadColumnProperties: true,
    excel_format: false,
    rapidAPIMessage: "",
    rapidAPIFromInput: "",
    rapidAPIOnBlur: false,
    fullScreenAnalyticsGraphTitle: "",
    tableColName: "default",
    download_without_api_call: false,
    download_threshold: 0,
};

const wellsAndRigsSlice = createSlice({
    name: "wellsAndRigs",
    initialState: initialState,
    reducers: {
        showHideComp(state, action: PayloadAction) {
            return {
                ...state,
                comp: !state.comp,
            };
        },
        showHideAdvFilter(state, action: PayloadAction) {
            return {
                ...state,
                advFilter: !state.advFilter,
            };
        },
        showHideColProperties(
            state,
            action: PayloadAction<{
                actionState: boolean;
            }>
        ) {
            return {
                ...state,
                colProperties: action.payload.actionState,
            };
        },
        showHideFullScreen(state, action: PayloadAction) {
            return {
                ...state,
                fullScreen: !state.fullScreen,
            };
        },
        showHideCsvDownOpt(state, action: PayloadAction) {
            return {
                ...state,
                csvDownOpt: !state.csvDownOpt,
            };
        },
        updateTableColName(
            state,
            action: PayloadAction<{
                tableColName: WellsRigsModel["tableColName"];
            }>
        ) {
            return {
                ...state,
                ...(action.payload.tableColName && {
                    tableColName: action.payload.tableColName,
                }),
            };
        },
        updateWellsTableCol(
            state,
            action: PayloadAction<WellsRigsModel["tableCol"]>
        ) {
            return {
                ...state,
                ...{ tableCol: [...action.payload] },
            };
        },
        updateRigsTableCol(
            state,
            action: PayloadAction<WellsRigsModel["rigsTableCol"]>
        ) {
            return {
                ...state,
                ...{ rigsTableCol: [...action.payload] },
            };
        },
        updateWellsOrRigsTableColFullList(
            state,
            action: PayloadAction<{
                data:
                | WellsRigsModel["tableColFullList"]
                | WellsRigsModel["rigsTableColFullList"]
                | WellsRigsModel["productionCol"];
                tabIndex?: WellsRigsModel["tabIndex"];
                tableColName?: WellsRigsModel["tableColName"];
            }>
        ) {
            return {
                ...state,
                ...(((state.tabIndex === 0 &&
                    typeof action.payload.tabIndex === "undefined") ||
                    action.payload.tabIndex === 0) && {
                    tableColFullList: [...action.payload.data],
                }),
                ...(((state.tabIndex === 1 &&
                    typeof action.payload.tabIndex === "undefined") ||
                    action.payload.tabIndex === 1) && {
                    rigsTableColFullList: [...action.payload.data],
                }),
                ...(((state.tabIndex === 3 &&
                    typeof action.payload.tabIndex === "undefined") ||
                    action.payload.tabIndex === 3) && {
                    productionCol: [...action.payload.data],
                }),
                ...(action.payload.tableColName && {
                    tableColName: action.payload.tableColName,
                }),
                loadColumnProperties: false,
            };
        },

        toggleChooseColExportToCsvModal(state, action: PayloadAction) {
            return {
                ...state,
                chooseColExportToCsvModal: !state.chooseColExportToCsvModal,
            };
        },
        toggleExportOtherCsvModal(state, action: PayloadAction) {
            return {
                ...state,
                exportOtherCsvModal: !state.exportOtherCsvModal,
            };
        },
        resetWellsAndRigsSliceToInitial(
            state,
            action: PayloadAction<{ holdPrevVal: boolean }>
        ) {
            return {
                ...initialState,
                mapData: state.mapData,
                mapLineData: state.mapLineData,
                ...(action.payload.holdPrevVal && {
                    tabIndex: state.tabIndex,
                }),
                ...(action.payload.holdPrevVal && {
                    tableCol: state.tableCol,
                }),
                ...(action.payload.holdPrevVal && {
                    rigsTableCol: state.rigsTableCol,
                }),
                ...(action.payload.holdPrevVal && {
                    wellsData: state.wellsData,
                }),
                ...(action.payload.holdPrevVal && {
                    rigsData: state.rigsData,
                }),
                ...(action.payload.holdPrevVal && {
                    permitsData: state.permitsData,
                }),
                ...(action.payload.holdPrevVal && {
                    productionData: state.productionData,
                }),
                ...(action.payload.holdPrevVal &&
                    state.fullScreen && {
                    fullScreen: state.fullScreen,
                }),
                ...(action.payload.holdPrevVal && {
                    wellsPage: state.wellsPage,
                }),
                ...(action.payload.holdPrevVal && {
                    rigsPage: state.rigsPage,
                }),
                ...(action.payload.holdPrevVal && {
                    permitsPage: state.permitsPage,
                }),
                ...(action.payload.holdPrevVal &&
                    state.selectedAoiData.aoi_id && {
                    selectedAoiData: state.selectedAoiData,
                }),
                ...(action.payload.holdPrevVal && {
                    filterSearch: state.filterSearch,
                }),
                ...{ rigsTableColFullList: state.rigsTableColFullList },
                ...{ rigsTableCol: state.rigsTableCol },
                ...{ tableCol: state.tableCol },
                ...{ tableColFullList: state.tableColFullList },
            };
        },
        loadWellsData(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["wellsData"]["data"];
                total_count: number;
                page_size: WellsRigsModel["wellsData"]["page_size"];
                total_permit: number;
                total_rigs: number;
                total_production: number;
                notConCatData?: boolean;
                download_threshold?: number;
            }>
        ) {
            return {
                ...state,
                wellsData: {
                    data: action.payload.notConCatData
                        ? [
                            ...(action.payload.data || []),
                            //   .map((item) => ({
                            //       ...item,
                            //     //   checked: false,
                            //   })),
                        ]
                        : [
                            ...(state.wellsData.data || []),
                            ...(action.payload.data || []),
                            //   .map((item) => ({
                            //       ...item,
                            //     //   checked: false,
                            //   })),
                        ],
                    total_count: action.payload.total_count,
                    wellsDataLoading: false,
                    page_size: action.payload.page_size,
                },
                rigsData: {
                    ...state.rigsData,
                    total_count: action.payload.total_rigs,
                },
                permitsData: {
                    ...state.permitsData,
                    total_count: action.payload.total_permit,
                },
                productionData: {
                    ...state.productionData,
                    total_count: action.payload.total_production,
                },
                ...("download_threshold" in action.payload && {
                    download_threshold: action.payload.download_threshold,
                }),
            };
        },
        loadRigsData(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["rigsData"]["data"];
                total_count: number;
                page_size: WellsRigsModel["rigsData"]["page_size"];
                total_permit: number;
                total_well: number;
                total_production: number;
                notConCatData?: boolean;
                download_threshold?: number;
            }>
        ) {
            return {
                ...state,
                rigsData: {
                    data: action.payload.notConCatData
                        ? [
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ]
                        : [
                            ...(state.rigsData.data || []),
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ],
                    total_count: action.payload.total_count,
                    rigsDataLoading: false,
                    page_size: action.payload.page_size,
                },
                // tableCol: [],
                wellsData: {
                    ...state.wellsData,
                    total_count: action.payload.total_well,
                },
                permitsData: {
                    ...state.permitsData,
                    total_count: action.payload.total_permit,
                },
                productionData: {
                    ...state.productionData,
                    total_count: action.payload.total_production,
                },
                ...("download_threshold" in action.payload && {
                    download_threshold: action.payload.download_threshold,
                }),
            };
        },
        loadPermitsData(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["permitsData"]["data"];
                total_count: number;
                page_size: WellsRigsModel["permitsData"]["page_size"];
                total_rigs: number;
                total_well: number;
                total_production: number;
                notConCatData?: boolean;
            }>
        ) {
            return {
                ...state,
                permitsData: {
                    data: action.payload.notConCatData
                        ? [
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ]
                        : [
                            ...(state.permitsData.data || []),
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ],
                    total_count: action.payload.total_count,
                    permitsDataLoading: false,
                    page_size: action.payload.page_size,
                },
                wellsData: {
                    ...state.wellsData,
                    total_count: action.payload.total_well,
                },
                rigsData: {
                    ...state.rigsData,
                    total_count: action.payload.total_rigs,
                },
                productionData: {
                    ...state.productionData,
                    total_count: action.payload.total_production,
                },
            };
        },
        handleTabIndex(
            state,
            action: PayloadAction<WellsRigsModel["tabIndex"]>
        ) {
            return {
                ...state,
                tabIndex: action.payload,
            };
        },
        handleSelectedAoiData(
            state,
            action: PayloadAction<WellsRigsModel["selectedAoiData"]>
        ) {
            return {
                ...state,
                selectedAoiData: action.payload,
            };
        },
        clearWellsData(state, action: PayloadAction) {
            return {
                ...state,
                wellsData: {
                    ...state.wellsData,
                    wellsDataLoading: true,
                },
            };
        },
        clearRigsData(state, action: PayloadAction) {
            return {
                ...state,
                rigsData: {
                    ...state.rigsData,
                    rigsDataLoading: true,
                },
            };
        },
        clearPermitData(state, action: PayloadAction) {
            return {
                ...state,
                permitsData: {
                    ...state.permitsData,
                    permitsDataLoading: true,
                },
            };
        },
        clearProductionData(state, action: PayloadAction) {
            return {
                ...state,
                productionData: {
                    ...state.productionData,
                    productionDataLoading: true,
                },
            };
        },
        handlePageChange(
            state,
            action: PayloadAction<WellsRigsModel["wellsPage"]>
        ) {
            return {
                ...state,
                ...(state.tabIndex === 0 && {
                    wellsPage: action.payload,
                }),
                ...(state.tabIndex === 1 && {
                    rigsPage: action.payload,
                }),
                ...(state.tabIndex === 2 && {
                    permitsPage: action.payload,
                }),
                ...(state.tabIndex === 3 && {
                    productionPage: action.payload,
                }),
            };
        },
        setFilterSearch(
            state,
            action: PayloadAction<WellsRigsModel["filterSearch"]>
        ) {
            return {
                ...state,
                filterSearch: action.payload,
            };
        },
        clearFilterSearch(state, action: PayloadAction) {
            return {
                ...state,
                filterSearch: "",
            };
        },
        handleSortAndOrderBy(
            state,
            action: PayloadAction<{
                sort_by?: WellsRigsModel["sort_by"];
                sort_order: WellsRigsModel["sort_order"];
            }>
        ) {
            return {
                ...initialState,
                ...(state.tabIndex === 0 && { tableCol: state.tableCol }),
                ...(state.tabIndex === 1 && {
                    rigsTableCol: state.rigsTableCol,
                }),
                selectedAoiData: state.selectedAoiData,
                tabIndex: state.tabIndex,
                filterSearch: state.filterSearch,
                sort_by: action.payload.sort_by || state.sort_by,
                sort_order: action.payload.sort_order,
                ...{ rigsTableColFullList: state.rigsTableColFullList },
                ...{ rigsTableCol: state.rigsTableCol },
                ...{ tableCol: state.tableCol },
                ...{ tableColFullList: state.tableColFullList },
            };
        },
        toggleViewAnalytics(state, action: PayloadAction<boolean | undefined>) {
            if (action.payload !== undefined) {
                return {
                    ...state,
                    viewAnalytics: action.payload,
                };
            }
            return {
                ...state,
                viewAnalytics: Boolean(!state.viewAnalytics),
            };
        },
        handleAdvFilterSearch(
            state,
            action: PayloadAction<{
                filter: WellsRigsModel["filter"];
                filter_param: WellsRigsModel["filter_param"];
                segment_id: WellsRigsModel["segment_id"];
            }>
        ) {
            return {
                ...state,
                filter: action.payload.filter,
                filter_param: action.payload.filter_param,
                segment_id: action.payload.segment_id,
            };
        },
        handleTableLoader(
            state,
            action: PayloadAction<WellsRigsModel["showTableLoader"]>
        ) {
            return {
                ...state,
                showTableLoader: action.payload,
            };
        },
        handleSelectedRowId(
            state,
            action: PayloadAction<WellsRigsModel["selectedRowId"]>
        ) {
            return {
                ...state,
                selectedRowId: action.payload,
            };
        },
        handleDownloadCol(
            state,
            action: PayloadAction<{
                downloadCol: WellsRigsModel["downloadCol"];
                allCol?: WellsRigsModel["allCol"];
                excel_format?: WellsRigsModel["excel_format"];
                download_without_api_call?: WellsRigsModel["download_without_api_call"];
            }>
        ) {
            return {
                ...state,
                downloadCol: action.payload.downloadCol,
                ...((action.payload.allCol === 0 ||
                    action.payload.allCol === 1) && {
                    allCol: action.payload.allCol,
                }),
                ...("excel_format" in action.payload && {
                    excel_format: action.payload.excel_format,
                }),
                ...("download_without_api_call" in action.payload && {
                    download_without_api_call:
                        action.payload.download_without_api_call,
                }),
            };
        },
        handleDownloadColMsg(
            state,
            action: PayloadAction<WellsRigsModel["downloadColMsg"]>
        ) {
            return {
                ...state,
                downloadColMsg: action.payload,
            };
        },
        handleShowAndHideTable(
            state,
            action: PayloadAction<WellsRigsModel["showTable"]>
        ) {
            return {
                ...state,
                showTable: action.payload,
                ...(!action.payload && { fullScreen: action.payload }),
            };
        },
        handleShowAndHideSegmentDropDown(
            state,
            action: PayloadAction<WellsRigsModel["showSegmentDropDown"]>
        ) {
            return {
                ...state,
                showSegmentDropDown: action.payload,
            };
        },
        loadProductionData(
            state,
            action: PayloadAction<{
                total_well: number;
                total_rigs: number;
                total_permit: number;
                total_count: number;
                page_size: WellsRigsModel["productionData"]["page_size"];
                data: WellsRigsModel["productionData"]["data"];
                notConCatData?: boolean;
            }>
        ) {
            return {
                ...state,
                productionData: {
                    data: action.payload.notConCatData
                        ? [
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ]
                        : [
                            ...(state.productionData.data || []),
                            //   ...(action.payload.data || []).map((item) => ({
                            //       ...item,
                            //       checked: false,
                            //   })),
                            ...(action.payload.data || []),
                        ],
                    total_count: action.payload.total_count,
                    page_size: action.payload.page_size,
                    productionDataLoading: false,
                },
                wellsData: {
                    ...state.wellsData,
                    total_count: action.payload.total_well,
                },
                rigsData: {
                    ...state.rigsData,
                    total_count: action.payload.total_rigs,
                },
                permitsData: {
                    ...state.permitsData,
                    total_count: action.payload.total_permit,
                },
            };
        },
        handleAnalyticsData(
            state,
            action: PayloadAction<{
                prodLiq: WellsRigsModel["analyticsData"]["prodLiq"];
                prodGas: WellsRigsModel["analyticsData"]["prodGas"];
                gasAggDate: WellsRigsModel["analyticsData"]["gasAggDate"];
                gasAggMonth: WellsRigsModel["analyticsData"]["gasAggMonth"];
                liqAggDate: WellsRigsModel["analyticsData"]["liqAggDate"];
                liqAggMonth: WellsRigsModel["analyticsData"]["liqAggMonth"];
                singleWellProd: WellsRigsModel["analyticsData"]["singleWellProd"];
                singleWellLeaseProd: WellsRigsModel["analyticsData"]["singleWellLeaseProd"];
                leaseIDs: WellsRigsModel["analyticsData"]["leaseIDs"];
                hasWellProd: WellsRigsModel["analyticsData"]["hasWellProd"];
                hasLeaseProd: WellsRigsModel["analyticsData"]["hasLeaseProd"];
                allocated: WellsRigsModel["analyticsData"]["allocated"];
                graphDataLoading?: WellsRigsModel["analyticsData"]["graphDataLoading"];
            }>
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    ...(action.payload.prodLiq && {
                        prodLiq: action.payload.prodLiq,
                    }),
                    ...(action.payload.prodGas && {
                        prodGas: action.payload.prodGas,
                    }),
                    ...(action.payload.gasAggDate && {
                        gasAggDate: action.payload.gasAggDate,
                    }),
                    ...(action.payload.gasAggMonth && {
                        gasAggMonth: action.payload.gasAggMonth,
                    }),
                    ...(action.payload.liqAggDate && {
                        liqAggDate: action.payload.liqAggDate,
                    }),
                    ...(action.payload.liqAggMonth && {
                        liqAggMonth: action.payload.liqAggMonth,
                    }),
                    ...(action.payload.singleWellProd && {
                        singleWellProd: action.payload.singleWellProd,
                    }),
                    ...(action.payload.singleWellLeaseProd && {
                        singleWellLeaseProd: action.payload.singleWellLeaseProd,
                    }),
                    ...(action.payload.leaseIDs && {
                        leaseIDs: action.payload.leaseIDs,
                    }),
                    ...(typeof action.payload.hasWellProd === "boolean" && {
                        hasWellProd: action.payload.hasWellProd,
                    }),
                    ...(typeof action.payload.hasLeaseProd === "boolean" && {
                        hasLeaseProd: action.payload.hasLeaseProd,
                    }),
                    ...(typeof action.payload.allocated === "boolean" && {
                        allocated: action.payload.allocated,
                    }),
                    ...(typeof action.payload.graphDataLoading ===
                        "boolean" && {
                        graphDataLoading: action.payload.graphDataLoading,
                    }),
                },
            };
        },
        handleFullScrnAnalytics(
            state,
            action: PayloadAction<{
                fullScrnAnalytics: WellsRigsModel["fullScrnAnalytics"];
                fullScrnAnalyticsType?: WellsRigsModel["fullScrnAnalyticsType"];
            }>
        ) {
            return {
                ...state,
                fullScrnAnalytics: action.payload.fullScrnAnalytics,
                ...(action.payload.fullScrnAnalyticsType && {
                    fullScrnAnalyticsType: action.payload.fullScrnAnalyticsType,
                }),
            };
        },
        handleSelectedWellRigOrPermitList(
            state,
            action: PayloadAction<WellsRigsModel["selectedWellRigOrPermitList"]>
        ) {
            return {
                ...state,
                selectedWellRigOrPermitList: action.payload,
            };
        },
        handleSelectedWellRigOrPermitUIDList(
            state,
            action: PayloadAction<
                WellsRigsModel["selectedWellRigOrPermitUIDList"]
            >
        ) {
            return {
                ...state,
                selectedWellRigOrPermitUIDList: action.payload,
            };
        },
        handleDonutChart(
            state,
            action: PayloadAction<{
                dataLoading?: WellsRigsModel["donutChart"]["dataLoading"];
                data: WellsRigsModel["donutChart"]["dataList"];
            }>
        ) {
            return {
                ...state,
                donutChart: {
                    dataList: action.payload.data,
                    dataLoading: false,
                    ...(action.payload.dataLoading && {
                        dataLoading: action.payload.dataLoading,
                    }),
                },
            };
        },
        handleUpdatedMapLineData(
            state,
            action: PayloadAction<{
                data: WellLineObject[];
                tile: number;
            }>
        ) {
            if (action.payload.tile < state.mapLineData.length) {
                state.mapLineData[action.payload.tile].data =
                    action.payload.data;
                //state.mapData[action.payload.tile].controller = action.payload.controller;
            }
        },
        handleUpdatedMapData(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["wellsData"]["data"];
                lineData: WellLineObject[];
                tile: number;
            }>
        ) {
            if (action.payload.tile < 8) {
                state.mapData[action.payload.tile].data = action.payload.data
                    ? action.payload.data.map((item) => {
                        const operatorName =
                            item.operator_name && item.operator_name.trim()
                                ? item.operator_name
                                : "N/A";
                        const reservoir =
                            item.reservoir && item.reservoir.trim()
                                ? item.reservoir
                                : "N/A";
                        item.reservoir = reservoir;
                        item.operator_name = operatorName;
                        return item;
                    })
                    : [];

                const _lineData = action.payload.lineData.map((lineItem) => {
                    const pointDataItem = action.payload.data?.find((item) => {
                        return item.well_api === lineItem.well_api;
                    });

                    if (pointDataItem) {
                        lineItem.operator_name = pointDataItem.operator_name;
                        lineItem.reservoir = pointDataItem.reservoir;
                    }
                    return lineItem;
                });
                state.mapLineData[action.payload.tile].data = _lineData;
                //state.mapData[action.payload.tile].controller = action.payload.controller;
            }
        },
        handleOperatorPieChart(
            state,
            action: PayloadAction<{
                operatorPieChartDataLoading?: WellsRigsModel["operatorPieChart"]["operatorPieChartDataLoading"];
                data: WellsRigsModel["operatorPieChart"]["operatorPieChartDataList"];
            }>
        ) {
            return {
                ...state,
                operatorPieChart: {
                    operatorPieChartDataLoading: false,
                    operatorPieChartDataList: action.payload.data,
                    ...(action.payload.operatorPieChartDataLoading && {
                        operatorPieChartDataLoading: true,
                    }),
                },
            };
        },
        handleAnalyticsTabIndex(
            state,
            action: PayloadAction<WellsRigsModel["analyticsTabIndex"]>
        ) {
            return {
                ...state,
                analyticsTabIndex: action.payload,
            };
        },
        handleProductionTabIndex(
            state,
            action: PayloadAction<WellsRigsModel["productionTabIndex"]>
        ) {
            return {
                ...state,
                productionTabIndex: action.payload,
            };
        },
        handleAnalyticsProdFilters(
            state,
            action: PayloadAction<{
                type?: WellsRigsModel["analyticsData"]["type"];
                action?: WellsRigsModel["analyticsData"]["action"];
                xAxisFilterVal?: WellsRigsModel["analyticsData"]["xAxisFilter"];
                apiList?: WellsRigsModel["analyticsData"]["apiList"];
                apiListObj?: WellsRigsModel["analyticsData"]["apiListObj"];
                apiListObjLength?: WellsRigsModel["analyticsData"]["apiListObjLength"];
            }>
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    ...(action.payload.type && {
                        type: action.payload.type,
                    }),
                    ...(action.payload.xAxisFilterVal && {
                        xAxisFilter: action.payload.xAxisFilterVal,
                    }),
                    ...(action.payload.action && {
                        action: action.payload.action,
                    }),
                    ...(action.payload.apiList && {
                        apiList: action.payload.apiList,
                    }),
                    ...(action.payload.apiListObj && {
                        apiListObj: action.payload.apiListObj,
                    }),
                    ...(action.payload.apiListObjLength &&
                        action.payload.apiListObjLength >= 0 && {
                        apiListObjLength: action.payload.apiListObjLength,
                    }),
                },
            };
        },
        handleNormalize(
            state,
            action: PayloadAction<WellsRigsModel["analyticsData"]["normalized"]>
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    normalized: action.payload,
                },
            };
        },
        handleForecast(
            state,
            action: PayloadAction<WellsRigsModel["openForeCast"]>
        ) {
            return {
                ...state,
                openForeCast: action.payload,
            };
        },
        handleResizableWidth(
            state,
            action: PayloadAction<WellsRigsModel["resizableWidth"]>
        ) {
            return {
                ...state,
                resizableWidth: action.payload,
            };
        },
        handleResizableHeight(
            state,
            action: PayloadAction<WellsRigsModel["resizableHeight"]>
        ) {
            return {
                ...state,
                resizableHeight: action.payload,
            };
        },
        handleForecastingData(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["analyticsData"]["forecastingData"]["dataList"];
                eur?: WellsRigsModel["analyticsData"]["forecastingData"]["eur"];
                forecastingCompleteDataFrame?: WellsRigsModel["analyticsData"]["forecastingData"]["forecastingCompleteDataFrame"];
                dataLoading?: WellsRigsModel["analyticsData"]["forecastingData"]["dataLoading"];
                ai?: WellsRigsModel["analyticsData"]["forecastingData"]["ai"];
                b?: WellsRigsModel["analyticsData"]["forecastingData"]["b"];
                qi?: WellsRigsModel["analyticsData"]["forecastingData"]["qi"];
                tlim?: WellsRigsModel["analyticsData"]["forecastingData"]["tlim"];
                start_date_select?: WellsRigsModel["analyticsData"]["forecastingData"]["start_date_select"];
                peakmo?: WellsRigsModel["analyticsData"]["forecastingData"]["peakmo"];
            }>
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    forecastingData: {
                        ...state.analyticsData.forecastingData,
                        dataList: action.payload.data,
                        ...((action.payload.forecastingCompleteDataFrame ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            forecastingCompleteDataFrame:
                                action.payload.forecastingCompleteDataFrame ||
                                "",
                        }),
                        ...((action.payload.eur ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            eur: action.payload.eur || "",
                        }),
                        ...((action.payload.ai ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            ai: action.payload.ai || "",
                        }),
                        ...((action.payload.qi ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            qi: action.payload.qi || "",
                        }),
                        ...((action.payload.b ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            b: action.payload.b || "",
                        }),
                        ...((action.payload.tlim ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            tlim: action.payload.tlim || "",
                        }),
                        ...((action.payload.start_date_select ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            start_date_select:
                                action.payload.start_date_select || "",
                        }),
                        ...((action.payload.peakmo ||
                            action.payload.data === null ||
                            (Array.isArray(action.payload.data) &&
                                action.payload.data.length === 0)) && {
                            peakmo: action.payload.peakmo || 0,
                        }),
                        dataLoading: false,
                        ...(action.payload.dataLoading && {
                            dataLoading: action.payload.dataLoading,
                        }),
                    },
                },
            };
        },
        handleSelectedForecastPoints(
            state,
            action: PayloadAction<{
                data: WellsRigsModel["analyticsData"]["selectedForecastPoints"];
                doNotConCat?: boolean;
            }>
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    selectedForecastPoints: action.payload.doNotConCat
                        ? action.payload.data
                        : [
                            ...(state.analyticsData.selectedForecastPoints ||
                                []),
                            ...(action.payload.data || []),
                        ],
                },
            };
        },
        handleRearrange(state, action: PayloadAction) {
            return {
                ...state,
                reArrange: action.payload,
                ...(state.tabIndex === 0 && {
                    wellsData: {
                        ...state.wellsData,
                        data: [
                            ...(state.wellsData.data || []).filter((item) =>
                                state.selectedWellRigOrPermitUIDList.includes(
                                    item.id.toString()
                                )
                            ),
                            ...(state.wellsData.data || []).filter(
                                (item) =>
                                    !state.selectedWellRigOrPermitUIDList.includes(
                                        item.id.toString()
                                    )
                            ),
                        ],
                    },
                }),
                ...(state.tabIndex === 1 && {
                    rigsData: {
                        ...state.rigsData,
                        data: [
                            ...(state.rigsData.data || []).filter((item) =>
                                state.selectedWellRigOrPermitUIDList.includes(
                                    item.id.toString()
                                )
                            ),
                            ...(state.rigsData.data || []).filter(
                                (item) =>
                                    !state.selectedWellRigOrPermitUIDList.includes(
                                        item.id.toString()
                                    )
                            ),
                        ],
                    },
                }),
                ...(state.tabIndex === 3 && {
                    productionData: {
                        ...state.productionData,
                        data: [
                            ...(state.productionData.data || []).filter(
                                (item) =>
                                    state.selectedWellRigOrPermitUIDList.includes(
                                        item.id.toString()
                                    )
                            ),
                            ...(state.productionData.data || []).filter(
                                (item) =>
                                    !state.selectedWellRigOrPermitUIDList.includes(
                                        item.id.toString()
                                    )
                            ),
                        ],
                    },
                }),
            };
        },
        handleUIDList(
            state,
            action: PayloadAction<{
                selectedWellRigOrPermitUIDList?: string[];
                reset?: boolean;
                data?: (
                    | WellsAndPermitsObject
                    | RigsDataObj
                    | ProductionDataObj
                )[];
                doNotRemoveRow?: boolean;
            }>
        ) {
            return {
                ...state,
                ...(action.payload.reset && {
                    ...(state.tabIndex === 0 && {
                        wellsData: {
                            ...state.wellsData,
                            data: action.payload
                                .data as WellsAndPermitsObject[],
                            //prevUidList check is added to subtract the count when user select new well without closing the pop-up of previous open pop-up
                            // ...((action.payload.uid.length === 0 ||
                            //     prevUidList) && {
                            //     total_count:
                            //         "doNotRemoveRow" in action.payload &&
                            //         action.payload.doNotRemoveRow
                            //             ? state.wellsData.total_count
                            //             : state.wellsData.total_count -
                            //                   prevUidList >
                            //               0
                            //             ? state.wellsData.total_count -
                            //               prevUidList
                            //             : 0,
                            // }),
                        },
                    }),
                    ...(state.tabIndex === 1 && {
                        rigsData: {
                            ...state.rigsData,
                            data: action.payload.data as RigsDataObj[],
                            // total_count: (action.payload.data as RigsDataObj[])
                            //     .length,
                            // ...((action.payload.uid.length === 0 ||
                            //     prevUidList) && {
                            //     total_count:
                            //         "doNotRemoveRow" in action.payload &&
                            //         action.payload.doNotRemoveRow
                            //             ? state.rigsData.total_count
                            //             : state.rigsData.total_count -
                            //                   prevUidList >
                            //               0
                            //             ? state.rigsData.total_count -
                            //               prevUidList
                            //             : 0,
                            // }),
                        },
                    }),
                    ...(state.tabIndex === 2 && {
                        permitsData: {
                            ...state.permitsData,
                            data: action.payload
                                .data as WellsAndPermitsObject[],
                            // ...((action.payload.uid.length === 0 ||
                            //     prevUidList) && {
                            //     total_count:
                            //         "doNotRemoveRow" in action.payload &&
                            //         action.payload.doNotRemoveRow
                            //             ? state.permitsData.total_count
                            //             : state.permitsData.total_count -
                            //                   prevUidList >
                            //               0
                            //             ? state.permitsData.total_count -
                            //               prevUidList
                            //             : 0,
                            // }),
                        },
                    }),
                    ...(state.tabIndex === 3 && {
                        productionData: {
                            ...state.productionData,
                            data: action.payload.data as ProductionDataObj[],
                            // ...((action.payload.uid.length === 0 ||
                            //     prevUidList) && {
                            //     total_count:
                            //         "doNotRemoveRow" in action.payload &&
                            //         action.payload.doNotRemoveRow
                            //             ? state.productionData.total_count
                            //             : state.productionData.total_count -
                            //                   prevUidList >
                            //               0
                            //             ? state.productionData.total_count -
                            //               prevUidList
                            //             : 0,
                            // }),
                        },
                    }),
                }),
            };
        },
        setProductionDataLoading(
            state,
            action: PayloadAction<WellsRigsModel["productionDataLoading"]>
        ) {
            return {
                ...state,
                productionDataLoading: action.payload,
            };
        },
        updateWellsDataList(
            state,
            action: PayloadAction<WellsAndPermitsObject[]>
        ) {
            return {
                ...state,
                wellsData: { ...state.wellsData, data: action.payload },
            };
        },
        updateRigsDataList(state, action: PayloadAction<RigsDataObj[]>) {
            return {
                ...state,
                rigsData: { ...state.rigsData, data: action.payload },
            };
        },
        updatePermitsDataList(
            state,
            action: PayloadAction<WellsAndPermitsObject[]>
        ) {
            return {
                ...state,
                permitsData: { ...state.permitsData, data: action.payload },
            };
        },
        setGraphDataLoading(
            state,
            action: PayloadAction<
                WellsRigsModel["analyticsData"]["graphDataLoading"]
            >
        ) {
            return {
                ...state,
                analyticsData: {
                    ...state.analyticsData,
                    graphDataLoading: action.payload,
                },
            };
        },
        setRapidAPIMessage(
            state,
            action: PayloadAction<WellsRigsModel["rapidAPIMessage"]>
        ) {
            return {
                ...state,
                rapidAPIMessage: action.payload,
            };
        },
        setFullScreenAnalyticsGraphTitle(
            state,
            action: PayloadAction<
                WellsRigsModel["fullScreenAnalyticsGraphTitle"]
            >
        ) {
            return {
                ...state,
                fullScreenAnalyticsGraphTitle: action.payload,
            };
        },
        setRapidAPIFromInput(
            state,
            action: PayloadAction<WellsRigsModel["rapidAPIFromInput"]>
        ) {
            return {
                ...state,
                rapidAPIFromInput: action.payload,
            };
        },
        setRapidAPIOnBlur(
            state,
            action: PayloadAction<WellsRigsModel["rapidAPIOnBlur"]>
        ) {
            return {
                ...state,
                rapidAPIOnBlur: action.payload,
            };
        },
    },
});

export default wellsAndRigsSlice;
