export const supportSectionItems = [
    {
        name: "whats_new",
        label: "What's New",
        iconClass: "whats-new",
        link: "https://portal.productboard.com/6butzy9vd9pccwrap5bb7yf9/tabs/3-launched"
    },
    {
        name: "coming_soon",
        label: "Coming Soon",
        iconClass: "coming-soon",
        link: "https://portal.productboard.com/6butzy9vd9pccwrap5bb7yf9/tabs/1-under-consideration"
    },
    {
        name: "help_center",
        label: "Help Center",
        iconClass: "help-center",
        link: "https://energy-domain-marketplace-data.webflow.io/help-center"
    },
    {
        name: "ways_to_use_data",
        label: "Ways to use Data",
        iconClass: "ways-to-use-data",
        link: "https://energy-domain-marketplace-data.webflow.io/help-center-category/getting-started"
    },
    {
        name: "faqs",
        label: "FAQs",
        iconClass: "faqs",
        link: "https://energy-domain-marketplace-data.webflow.io/contact#faq"
    },
    {
        name: "chat",
        label: "Chat",
        iconClass: "chat",
        link: ""
    },
    {
        name: "give_feedback",
        label: "Give Feedback",
        iconClass: "give-feedback",
        link: "https://portal.productboard.com/6butzy9vd9pccwrap5bb7yf9/tabs/1-under-consideration/submit-idea"
    }
];
